import React, { useState, useEffect } from 'react';
import { signInWithEmailAndPassword, signInWithPhoneNumber, RecaptchaVerifier ,getAuth, sendPasswordResetEmail} from 'firebase/auth';
import { auth } from './firebaseConfig';
import { callApi, checkUserResponse } from './ApiHandler';
import './App.css';
const SignIn = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [verificationId, setVerificationId] = useState(null);
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [emailUid, setEmailUid] = useState(null);
  const [phoneUidFirebase, setPhoneUidFirebase] = useState(null);
  const [loading, setLoading] = useState(false); // State for loader
  const [modalOpen, setModalOpen] = useState(false); // State for modal
  const [modalOpenForget, setModalOpenForget] = useState(false); // Reuse existing modal state
  const [resetEmail, setResetEmail] = useState(''); // State for the reset email

  // Open the modal when user clicks "Forget Password"
  const openModalForget = () => {
    setResetEmail(''); // Clear email input field when opening modal
    setModalOpenForget(true);
  };

  // Close the modal
  const closeModalForget = () => {
    setModalOpenForget(false);
  };
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const emailFromUrl = params.get('email');
    if (emailFromUrl) {
      setEmail(emailFromUrl);
    }
  }, []);

  useEffect(() => {
    if (isEmailVerified) {
      setupRecaptcha();
    }
  }, [isEmailVerified]);

  const handleEmailSignIn = async (event) => {
    event.preventDefault();
    setError(null);
    setSuccessMessage(null);
    setLoading(true);

    if (!email || !password) {
      setError('Please enter both email and password.');
      setLoading(false);
      return;
    }

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      setEmailUid(userCredential.user.uid);
      try {
        console.log("UUUUUU")
        const User = await checkUserResponse(email);
        if (User === true) {
          // If user exists, proceed with the process
          setIsEmailVerified(true);
          setLoading(false);
          setSuccessMessage('Successfully signed in. Please enter your phone number next.');
        }
        else if (User === false) {
          setIsEmailVerified(false);
          setLoading(false);
          setSuccessMessage('Your account is already migrated. You can leave this page.');
        }
      } catch (error) {
        // If the user does not exist, show an error message and stop further processing
        setError('User does not exist.');
        setLoading(false);
        return;
      }
    } catch (err) {
      setError(`Incorrect Email or Password.`);
      setLoading(false);
    }
  };


  const handleSendOtp = async (event) => {
    const phoneNumberPattern = /^\+[1-9]\d{1,14}$/;
    if (!phoneNumberPattern.test(phoneNumber)) {
        alert('Phone number must start with a "+" followed by the country code and your phone number. Example: +436641234567');
        // setLoading(false);
        // return;
    }
    event.preventDefault();
    setError(null);
    setSuccessMessage(null);
    setLoading(true);
    
    if (!phoneNumber) {
      setError('Please enter your phone number.');
      setLoading(false);
      return;
    }

    try {
      if (!window.recaptchaVerifier) {
        setupRecaptcha();
      }
      await window.recaptchaVerifier.verify();
      console.log(phoneNumber)
      const confirmationResult = await signInWithPhoneNumber(auth, phoneNumber, window.recaptchaVerifier);
      setConfirmationResult(confirmationResult);
      setVerificationId(confirmationResult.verificationId);
      setSuccessMessage('SMS-Code sent to your phone. Please enter the SMS-Code to complete sign-in.');
    } catch (err) {
      console.log("err in sending the OTP",err)
      setError(`Error sending the SMS-Code. Please try again.`);
    } finally {
      setLoading(false);
    }
  };
  // Handle forget password logic
  const handleForgetPassword = async (event) => {
    event.preventDefault();
    setError(null);

    if (!resetEmail) {
      setError('Please enter your email.');
      return;
    }

    const auth = getAuth();
    try {
      await sendPasswordResetEmail(auth, resetEmail);
      
      closeModalForget(); // Close modal on success
      alert("Password reset email sent!");
    } catch (error) {
      alert("Failed to send password reset email. Please try again.");
    }
  };
  const handleVerifyOtp = async (event) => {
    event.preventDefault();
    setError(null);
    setSuccessMessage(null);
    setLoading(true);

    if (!otp) {
      setError('Please enter the SMS-Code.');
      setLoading(false);
      return;
    }

    if (!verificationId || !confirmationResult) {
      setError('No verification ID found. Please send the SMS-Code first.');
      setLoading(false);
      return;
    }

    try {
      const userCredential = await confirmationResult.confirm(otp);
      const phoneUid = userCredential.user.uid;
      setPhoneUidFirebase(phoneUid);

      const apiResponse = await callApi({
        emailUid,
        email,
        phoneNumber,
        phoneUid,
      });
      // setSuccessMessage(apiResponse);
      setModalOpen(true); // Open the modal
    } catch (err) {
      setError(`Error verifying the SMS-Code.`);
    } finally {
      setLoading(false);
    }
  };

 const setupRecaptcha = () => {
  console.log('aaaaaaa',auth);
  if (!auth) {
    console.error('Firebase Auth is not initialized');
    return;
  }
  try {
    window.recaptchaVerifier = new RecaptchaVerifier(auth,'recaptcha-container', {
      'size': 'invisible',
      'callback': (response) => {
        console.log("responseresponseresponse",response)
      },
      'expired-callback': () => {
        console.log('reCAPTCHA expired, resetting...');
      }
    });  // Ensure `auth` is correctly passed
  } catch (err) {
    console.error('reCAPTCHA setup failed:', err);
  }
};

  const closeModal = () => {
    setModalOpen(false);
    setIsEmailVerified(false)
  };

  return (
    <div className="auth-container">
      <h2 className="header">Account Migration</h2>
      <h4>Please log in with your Together-Account</h4>
      {!isEmailVerified ? (
        <form onSubmit={handleEmailSignIn}>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
          />
          <a href="#" onClick={openModalForget} className="link" >
          Forget Password?
        </a>
          <button type="submit" disabled={loading}>
            {loading ? 'Signing In...' : 'Sign In'}
          </button>
          
        </form>
      ) : (
        <>
          <form onSubmit={handleSendOtp}>
            <input
              type="tel"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              placeholder="Phone Number"
            />
            <span style={{ color: 'lightgrey', marginLeft: '10px', marginBottom: '5px' }}>
              e.g. <b style={{color:'red'}}>+43664</b><b>XXXXXXX</b>
            </span>
            <button type="submit" disabled={loading}>
              {loading ? 'Sending SMS-Code...' : 'Send SMS-Code'}
            </button>
          </form>
          {verificationId && (
            <form onSubmit={handleVerifyOtp}>
              <input
                type="text"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                placeholder="Enter SMS-Code"
              />
              <button type="submit" disabled={loading}>
                {loading ? 'Verifying...' : 'Verify SMS-Code'}
              </button>
            </form>
          )}
        </>
      )}
      {error && <p className="error-message">{error}</p>}
      {successMessage && <p className="success-message">{successMessage}</p>}
      <div id="recaptcha-container"></div>

      {modalOpen && (
        <div className="modal">
          <div className="modal-content">
            <h3>Success!</h3>
            <p>Your account has been migrated successfully! Please install the latest version of our
              application through App Store or Google Play Store and log in with your phone number.</p>
            <button onClick={closeModal}>Close</button>
          </div>
        </div>
      )}
      {modalOpenForget && (
        <div className="modalForget">
        <div className="modal-contentForget">
          <h3>Reset Password</h3>
          <form onSubmit={handleForgetPassword}>
            <input
              type="email"
              value={resetEmail}
              onChange={(e) => setResetEmail(e.target.value)}
              placeholder="Enter your email"
              required
            />
            <button type="submit">Send Reset Email</button>
          </form>
          <button onClick={closeModalForget}>Close</button>
        </div>
      </div>
      )}
    </div>
  );
};

export default SignIn;
