// src/firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyC2DzHj6IeMSyKu6SHvb-k4WWMyT3OjTtE",
    authDomain: "together-shared-digital-albums.firebaseapp.com",
    projectId: "together-shared-digital-albums",
    storageBucket: "together-shared-digital-albums.appspot.com",
    messagingSenderId: "120439964884",
    appId: "1:120439964884:web:e9bed5f413dfc4d5922741",
    measurementId: "G-J1J4C8LLTR"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth };





// // src/firebaseConfig.js
// import { initializeApp } from "firebase/app";
// import { getAuth } from "firebase/auth";

// const firebaseConfig = {
//     apiKey: 'AIzaSyDxOKA7kL-zBQWaw0fldH-ShQaM6w239AQ',
//     authDomain: 'together-79aca.firebaseapp.com',
//     projectId: 'together-79aca',
//     storageBucket: 'together-79aca.appspot.com',
//     messagingSenderId: '891109685382',
//     appId: '1:891109685382:web:118ad79394371d79a03907',
//     measurementId: 'G-LFRGQZBLXM'
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const auth = getAuth(app);

// export { auth };




