// Function to check if the user exists
export const checkUserResponse = async (email) => {
  console.log("Checking if user exists for email:", email);
  try {
    const emailString = String(email);
    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/getUid`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: emailString }),
    });

    // const data = await response.json();
    // if (!data.success) {
    //   // If the user does not exist, return a custom error message
    //   throw new Error('Migration already done or email not found. Please leave this page.');
    // }
const data = await response.json();
console.log()
    return data.success; // Return the UID if the user exists

  } catch (error) {
    // if()
    console.error('Error checking user existence:', error);
    throw new Error(`User existence check failed: ${error.message}`);
  }
};

// Function to handle the main API call
export const callApi = async ({ emailUid, email, phoneNumber, phoneUid }) => {

  try {
    // Ensure all required parameters are provided
    if (!emailUid || !email || !phoneNumber || !phoneUid) {
      throw new Error('Missing required parameters for API call.');
    }

    // Proceed with the main API call
    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/firebaseMigration`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        uid: emailUid,
        email: email,
        phoneNumber: phoneNumber,
        phoneUid: phoneUid,
        message: 'User successfully signed in with phone.',
      }),
    });


    if (!response.ok) {
      const errorMessage = await response.text();
      throw new Error(errorMessage || `API call failed with status ${response.status}`);
    }

    const data = await response.text(); // Assuming the response is plain text
    return data;

  } catch (error) {
    console.error('Error in API call:', error.message);
    throw new Error(`API call failed: ${error.message}`);
  }
};
